// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/billing/v2/FreePlanUsage.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/billing/v2/FreePlanUsage.tsx");
  import.meta.hot.lastModified = "1720450759787.4702";
}
// REMIX HMR END

import { ArrowUpCircleIcon } from "@heroicons/react/24/outline";
import { motion, useMotionValue, useTransform } from "framer-motion";
import { Paragraph } from "../../primitives/Paragraph";
import { Link } from "@remix-run/react";
import { cn } from "~/utils/cn";
export function FreePlanUsage({
  to,
  percentage
}) {
  _s();
  const cappedPercentage = Math.min(percentage, 1);
  const widthProgress = useMotionValue(cappedPercentage * 100);
  const color = useTransform(widthProgress, [0, 74, 75, 95, 100], ["#22C55E", "#22C55E", "#F59E0B", "#F43F5E", "#F43F5E"]);
  const hasHitLimit = cappedPercentage >= 1;
  return <div className={cn("rounded border border-charcoal-700 bg-charcoal-750 p-2.5", hasHitLimit && "border-error/40")}>
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-1">
          <ArrowUpCircleIcon className="h-5 w-5 text-text-dimmed" />
          <Paragraph className="text-2sm text-text-bright">Free Plan</Paragraph>
        </div>
        <Link to={to} className="text-2sm text-text-link">
          Upgrade
        </Link>
      </div>
      <div className="relative mt-3 h-1 rounded-full bg-background-dimmed">
        <motion.div initial={{
        width: 0
      }} animate={{
        width: cappedPercentage * 100 + "%"
      }} style={{
        backgroundColor: color
      }} transition={{
        duration: 1,
        type: "spring"
      }} className={cn("absolute left-0 top-0 h-full rounded-full")} />
      </div>
    </div>;
}
_s(FreePlanUsage, "FeEULVt0NQOwDScvrCmktpTxrqs=", false, function () {
  return [useMotionValue, useTransform];
});
_c = FreePlanUsage;
var _c;
$RefreshReg$(_c, "FreePlanUsage");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;